
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { useRoute, useRouter } from 'vue-router';
import { dateFormat } from '@/utils/helper/format';
import IconPallette from '@/components/icon/IconPallette.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const outerStoreHeader = [
  {
    name: 'Store',
    key: 'storeDetail',
    sortable: false,
  },
  {
    name: 'Owner',
    key: 'owner',
    sortable: false,
  },
  {
    name: 'Current Package',
    key: 'packageName',
    sortable: false,
  },
  {
    name: 'Date Created',
    key: 'createdAt',
    sortable: false,
  },
];

const storeHeader = [
  ...outerStoreHeader,
  {
    name: 'Actions Detail',
    key: 'moreDetail',
    sortable: false,
  },
];

export default {
  name: 'Commission List',
  components: {
    Datatable,
    IconPallette,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { id } = route.params;
    const joinStoreTableRef = ref<any>(null);
    const outerStoreTableRef = ref<any>(null);
    const formRef = ref<any>(null);
    const formData = ref<any>({
      name: null,
      commission: 15,
    });
    const state = reactive({
      isEdit: false,
      packageId: id,
      packageData: {} as any,
      joinedStoreId: [] as any[],
      wantToJoinStoreId: [] as any[],
      selectedStoreId: [] as any[],
      storeMasterData: [] as any[],
      searchKeyword: null,
      storeTableOption: {
        tableHeader: storeHeader,
        loading: false,
        total: 0,
        rowsPerPage: 9999,
        currentPage: 1,
      },
      outerStoreTableOption: {
        tableHeader: outerStoreHeader,
        loading: false,
        total: 0,
        rowsPerPage: 9999,
        currentPage: 1,
      },
    });

    const breadcrumbs = ['Commission Setting', ['Commission Detail']];

    const fetch = async (page = 1, rowsPerPage = 10) => {
      try {
        const queryCondition: any = {
          page,
          pageSize: rowsPerPage,
        };

        const { data } = await ApiService.get(
          `/store?${new URLSearchParams(queryCondition)}`
        );
        if (data.status) {
          state.storeTableOption.total = data.total;
          state.storeMasterData = data.datas;
        }
        state.storeTableOption.loading = false;
      } catch (error) {
        state.storeTableOption.loading = false;
      }
    };

    const allStoreData = computed(() => {
      return state.storeMasterData;
    });

    const joinedStoreData = computed(() => {
      const tmp: any[] = [];
      state.storeMasterData.forEach((partnerStore: any) => {
        if (state.joinedStoreId.includes(partnerStore.id)) {
          tmp.push(partnerStore);
        }
      });

      state.storeMasterData.forEach((partnerStore: any) => {
        if (state.wantToJoinStoreId.includes(partnerStore.id)) {
          tmp.push(partnerStore);
        }
      });

      return tmp;
    });

    const outerJoinedStoreData = computed(() => {
      let tmp: any[] = [];
      state.storeMasterData.forEach((partnerStore: any) => {
        const joinedWantJoin = [
          ...state.joinedStoreId,
          ...state.wantToJoinStoreId,
        ];
        if (!joinedWantJoin.includes(partnerStore.id)) {
          tmp.push({ ...partnerStore, selected: false });
        }
      });

      if (state.searchKeyword) {
        tmp = tmp.filter((ps) => {
          return `${ps.name}`.includes(`${state.searchKeyword}`);
        });
      }

      return tmp;
    });

    const handleSelected = (e) => {
      e.preventDefault();
      if (e.target.checked) {
        state.selectedStoreId.push(e.srcElement.dataset.id);
      } else {
        const idx = state.selectedStoreId.indexOf(e.srcElement.dataset.id);
        if (idx > -1) {
          state.selectedStoreId.splice(idx, 1);
        }
      }
    };

    const toggleMode = () => {
      state.isEdit = !state.isEdit;
      state.searchKeyword = null;
    };

    const addStoreSelected = () => {
      state.selectedStoreId.forEach((id) => {
        state.wantToJoinStoreId.push(id);
      });
      state.selectedStoreId = [];
      state.isEdit = false;
    };

    const submitForm = async (e) => {
      e.preventDefault();
      try {
        if (!formData.value.name || !`${formData.value.name}`.length) {
          throw new Error('name is required');
        }

        let packageResponse;
        if (state.packageId && state.packageId !== 'add') {
          //update
          const { data } = await ApiService.patch(
            `/package/${state.packageId}`,
            {
              name: formData.value.name,
              commission: formData.value.commission,
            }
          );
          if (!data.status) {
            throw new Error('save failed');
          }
        } else {
          //create
          const { data } = await ApiService.post(`/package`, {
            name: formData.value.name,
            commission: formData.value.commission,
          });

          if (!data.status) {
            throw new Error('save failed');
          }
          packageResponse = data.data;
        }

        const { data: storePackages } = await ApiService.patch(
          `/store/packages`,
          {
            packageId: packageResponse ? packageResponse.id : state.packageId,
            stores: [...state.wantToJoinStoreId, ...state.joinedStoreId],
          }
        );

        if (!storePackages.status) {
          throw new Error('save packages failed');
        }

        await Swal.fire({
          text: 'อัพเดทแพ็คเกจสำเร็จ',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'รับทราบ!',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });

        router.push({ name: 'packageList' });
      } catch (error) {
        console.error(error);
        Swal.fire({
          text: `ไม่สามารถบันทึกได้`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    };

    const selectAllStore = () => {
      outerJoinedStoreData.value.forEach((store) => {
        if (!state.selectedStoreId.includes(store.id)) {
          state.selectedStoreId.push(store.id);
        }
      });
    };

    const refetch = async () => {
      if (state.packageId && state.packageId !== 'add') {
        const { data } = await ApiService.get(`/package/${state.packageId}`);
        if (data.status) {
          state.packageData = data.data;
          formData.value.name = data.data.name;
          formData.value.commission = data.data.commission;
        }
      }
      await fetch(1, state.storeTableOption.rowsPerPage);
      state.joinedStoreId = state.storeMasterData
        .filter((partnerStore: any) => {
          return `${partnerStore.package?.id}` === `${state.packageId}`;
        })
        .map((filteredPartnerStore: any) => {
          return filteredPartnerStore.id;
        });

      return true;
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        breadcrumbs[0] as string,
        breadcrumbs[1] as Array<Record<string, unknown> | string>,
        {}
      );
      await refetch();
    });

    watch(
      () => id,
      () => {
        state.packageId = route.params.id;
      }
    );

    return {
      ...toRefs(state),
      formRef,
      formData,
      breadcrumbs,
      joinStoreTableRef,
      outerStoreTableRef,
      dateFormat,
      allStoreData,
      joinedStoreData,
      outerJoinedStoreData,
      toggleMode,
      handleSelected,
      addStoreSelected,
      submitForm,
      selectAllStore,
    };
  },
};
